<template>
  <div v-if="offer" class="document">
    <OfferPrintFirstPage :offer="offer" />
    <OfferPrintSecondPage :offer="offer" />
    <OfferPrintThirdPage :offer="offer" />
  </div>
  <div v-else>Keine Daten vorhanden!</div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import OfferPrintFirstPage from '@/components/Print/OfferPrintFirstPage';
import OfferPrintSecondPage from '@/components/Print/OfferPrintSecondPage';
import OfferPrintThirdPage from '@/components/Print/OfferPrintThirdPage';

export default {
  name: 'OfferPrintPage',
  components: {
    OfferPrintFirstPage,
    OfferPrintSecondPage,
    OfferPrintThirdPage,
  },
  props: {
    offerNumber: {
      required: true,
      default: null,
    },
  },
  computed: {
    ...mapGetters('auth', ['isAdmin']),
    ...mapGetters(['getOfferByNumber']),
    offer() {
      return this.getOfferByNumber(this.offerNumber);
    },
    pages() {
      return this.offer.pageCount;
    },
    /** Check prerequesites to generate pdf */
    isReady() {
      return true;
    },
  },
  methods: {
    ...mapActions(['fetchOfferByNumber']),
    getFieldsByPage(index) {
      const page = index + 1;
      return this.document.fields.filter((field) => field.page == page);
    },
  },
  async mounted() {
    this.number = this.offerNumber;
    await this.fetchOfferByNumber(this.offerNumber);
  },
};
</script>
<style lang="scss" scoped>
.document {
  position: absolute;
  width: 100%;
  height: 100%;
  font-size: 13px;
}

@page {
  size: A4;
  margin: 0;
}

@media print {
  html,
  body {
    width: 210mm;
    height: 297mm;
  }
  .page {
    background-size: cover;
    background-repeat: no-repeat;
    border: 0px solid red;
    page-break-after: always;
    margin: 0;
    padding: 1cm 1cm 1cm 2.2cm;
    background-position: center center;
    position: relative;
    width: 100%;
    height: 100%;
    // top: 0;
    // left: 0;
    font-size: 13pt;
  }
  .field {
    position: absolute;
    // border: 1px solid rebeccapurple;
  }
}
</style>
